import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from 'src/components/layout';
import SEO from 'src/components/seo';
import { StoriesSlide, StoriesTable } from 'src/components/stories';
import { chunk } from 'lodash';
import Signup from 'src/components/Signup';
import Carousel from 'src/components/Carousel';

const InTheNews = () => {
  const data = useStaticQuery(graphql`
    query inTheNewsData {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/in-the-news.md/" } }) {
        edges {
          node {
            frontmatter {
              intro
              featuredStory {
                image
                source
                title
                description
                link
              }
              newsStories {
                date
                source
                title
                link
              }
            }
          }
        }
      }
    }
  `);

  const { intro, featuredStory, newsStories } =
    data?.allMarkdownRemark?.edges?.[0]?.node?.frontmatter ?? {};

  return (
    <Layout>
      <SEO title="In the News" />
      <main className={`bg-tan`}>
        <div className="px-4 container md:mx-auto py-6 md:py-6 lg:pt-8">
          <h1 className="text-[40px] font-[700] mb-3 w-full lg:w-1/2">In The News</h1>
          <p className="text-base font-[400] w-full lg:w-1/2">{intro}</p>
        </div>
        {featuredStory && (
          <div className="bg-bronze mx-auto p-[0] sm:p-[0px] md:p-0">
            <div className="container mx-auto flex flex-col-reverse items-center gap-4 md:flex-row py-4 md:py-10">
              <div className="md:w-3/5 text-white text-center md:text-left max-w-[800px] p-4">
                <div className=" uppercase font-bold mb-2 tracking-widest text-[14px]">
                  Featured News
                </div>
                <h2 className="mb-2 !font-bold">{featuredStory?.title}</h2>
                <p className="mb-4">{featuredStory?.description}</p>
                <a
                  className="block w-fit mx-auto md:mx-0 bg-white text-black uppercase py-2 px-3 font-bold tracking-widest text-[14px]"
                  href={featuredStory?.link}
                  target="_blank"
                >
                  Read on {featuredStory?.source}
                </a>
              </div>
              <img
                className="h-[400px] md:h-auto self-stretch w-full md:w-[400px] md:order-first object-cover md:pl-3"
                src={featuredStory?.image}
              />
            </div>
          </div>
        )}
        <div className="container px-4 md:px-[8rem] lg:px-[12rem] mx-auto bg-tan mt-4 mb-4">
          {/*<Carousel> */}
          {chunk(newsStories, 100).map((page) => (
            <div>
              {page.map(({ date, source, title, link }) => (
                <a
                  key={title}
                  className="block even:bg-brown h-auto py-4 md:py-5 px-3 md:px-10 font-bold cursor-pointer hover:bg-[#F2ECE0] text-black"
                  href={link}
                  target="_blank"
                >
                  <div className="uppercase tracking-widest text-[12px] md:text-[14px] mb-1 md:max-w-[720px] m-auto">
                    {new Date(date).toLocaleDateString(undefined, {
                      month: 'long',
                      year: 'numeric',
                    })}{' '}
                    - {source}
                  </div>
                  <div className="text-[21px] md:text-[24px] line-clamp-3 sm:line-clamp-2 md:max-w-[720px] m-auto">
                    {title}
                  </div>
                </a>
              ))}
            </div>
          ))}
          {/*</Carousel>*/}
        </div>
      </main>
      <Signup />
    </Layout>
  );
};

export default InTheNews;
